import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_GUEST_SERIVCE, GUEST_CREATE, GUEST_CREATE_COMMENT_URL, GUEST_DELETE, GUEST_DELETE_COMMENT_URL, GUEST_UPDATE_URL, UPDATE_NUMBER_PHONE_URL } from '../../endpoints';
import { Method } from '../../types';
import { GuestCreateApiProps, GuestDeleteApiProps, GuestsCreateCommentApiProps, GuestsDeleteCommentApiProps, GuestsUpdateApiProps, updateNumberPhoneApiProps } from './guest-update.types';

export const guestUpdateApi = createApi({
  reducerPath: 'guestUpdateApi',
  tagTypes: ['guestUpdate'],
  baseQuery: baseQueryWithAuth(BASE_URL_GUEST_SERIVCE),
  endpoints: builder => ({
    updateGuestData: builder.mutation<void, GuestsUpdateApiProps>({
      query: body => ({
        url: GUEST_UPDATE_URL,
        method: Method.Put,
        body: body,
      }),
    }),
    createComment: builder.mutation<void, GuestsCreateCommentApiProps>({
      query: body => ({
        url: GUEST_CREATE_COMMENT_URL,
        method: Method.Post,
        body: body,
      }),
    }),
    deleteComment: builder.mutation<void, GuestsDeleteCommentApiProps>({
      query: body => ({
        url: GUEST_DELETE_COMMENT_URL,
        method: Method.Delete,
        body: body,
      }),
    }),
    updateNumberPhone: builder.mutation<void, updateNumberPhoneApiProps>({
      query: body => ({
        url: UPDATE_NUMBER_PHONE_URL,
        method: Method.Put,
        body: body,
      }),
    }),
    deleteGuest: builder.mutation<unknown, GuestDeleteApiProps>({
      query: body => ({
        url: GUEST_DELETE,
        method: Method.Delete,
        body: body,
      }),
    }),
    createGuest: builder.mutation<unknown, GuestCreateApiProps>({
      query: body => ({
        url: GUEST_CREATE,
        method: Method.Post,
        body: body,
      }),
    }),
  }),
});

export const { useUpdateGuestDataMutation,useCreateCommentMutation, useDeleteCommentMutation,useUpdateNumberPhoneMutation,useDeleteGuestMutation,useCreateGuestMutation } = guestUpdateApi;
