import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'modules/user/containers/auth-container/auth-container.slice';
import { authApi } from '../api/auth/auth';
import { guestsApi } from '../api/guests/guests';
import { guestInfoApi } from '../api/guest-info/guest-info';
import { guestDocumentInfoApi } from '../api/guest-document-info/guest-document-info';
import { receivingInternalAccApi } from '../api/receiving-internal-account/receiving-internal-acc';
import { userInfoApi } from '../api/user-info/user-info';
import { commentListApi } from '../api/comment-list/comment-list';
import { loyaltyGuestApi } from '../api/loyalty-guest/loyalty-guest';
import { bookingSummaryApi } from '../api/booking-summary/booking-summary';
import { hotelsAllApi } from '../api/hotels-all/hotels-all';
import { currentBookingsApi } from '../api/current-bookings/current-bookings';
import { guestUpdateApi } from '../api/guest-update/guest-update';
import apiMiddleware from '../api/apiMiddleware/apiMiddleware';
import languageReducer from './language-reducer/language.slice';
import { bookingServiceChannelApi } from '../api/booking-service/booking-siervice-channel';
import { hotelServiceApi } from '../api/hotel-service/hotel-info/hotel-siervice';
import { bookingGuestApiGw } from '../api/api-gw/booking-guest-apigw';
import { bookingSummaryApiCSV } from '../api/booking-summary/booking-summary-csv';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    language: languageReducer,
    [authApi.reducerPath]: authApi.reducer,
    [guestsApi.reducerPath]: guestsApi.reducer,
    [guestInfoApi.reducerPath]: guestInfoApi.reducer,
    [guestDocumentInfoApi.reducerPath]: guestDocumentInfoApi.reducer,
    [receivingInternalAccApi.reducerPath]: receivingInternalAccApi.reducer,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [commentListApi.reducerPath]: commentListApi.reducer,
    [loyaltyGuestApi.reducerPath]: loyaltyGuestApi.reducer,
    [bookingSummaryApi.reducerPath]: bookingSummaryApi.reducer,
    [hotelsAllApi.reducerPath]: hotelsAllApi.reducer,
    [currentBookingsApi.reducerPath]: currentBookingsApi.reducer,
    [guestUpdateApi.reducerPath]: guestUpdateApi.reducer,
    [bookingServiceChannelApi.reducerPath]: bookingServiceChannelApi.reducer,
    [hotelServiceApi.reducerPath]: hotelServiceApi.reducer,
    [bookingGuestApiGw.reducerPath]: bookingGuestApiGw.reducer,
    [bookingSummaryApiCSV.reducerPath]: bookingSummaryApiCSV.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['bookingSummaryApiCSV/executeQuery/fulfilled'],
      },
    }).concat([
      apiMiddleware,
      authApi.middleware,
      guestsApi.middleware,
      guestInfoApi.middleware,
      guestDocumentInfoApi.middleware,
      receivingInternalAccApi.middleware,
      userInfoApi.middleware,
      commentListApi.middleware,
      loyaltyGuestApi.middleware,
      bookingSummaryApi.middleware,
      hotelsAllApi.middleware,
      currentBookingsApi.middleware,
      guestUpdateApi.middleware,
      bookingServiceChannelApi.middleware,
      hotelServiceApi.middleware,
      bookingGuestApiGw.middleware,
      bookingSummaryApiCSV.middleware,
    ]),
});

declare global {
  interface Window {
    reduxStore: typeof store;
  }
}

window.reduxStore = store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
