import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';

import { Method } from '../../types';
import { BASE_URL_API_GW, BOOKING_GUEST_CHECKIN } from '../../endpoints';

export const bookingGuestApiGw = createApi({
  reducerPath: 'bookingGuestApiGwService',
  tagTypes: ['bookingGuestApiGw'],
  baseQuery: baseQueryWithAuth(BASE_URL_API_GW),
  endpoints: builder => ({
    postGuestCheckIn: builder.mutation<
      unknown,
      { bookingId: string; guestId: string }
    >({
      query: body => ({
        url: BOOKING_GUEST_CHECKIN,
        method: Method.Post,
        body: body,
      }),
    }),
  }),
});

export const { usePostGuestCheckInMutation } = bookingGuestApiGw;
