import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';

import { Method } from '../../types';
import {
  BASE_URL_API_GW,
  BOOKING_EARLY_CHECK_IN,
  BOOKING_GUEST_CHECKIN,
  BOOKING_LATE_CHECK_OUT,
  BOOKING_UPGRADE_ROOMTYPE,
  MANAGE_BOOKING_EARLY_CHECK_IN,
  MANAGE_BOOKING_LATE_CHECK_OUT,
  MANAGE_BOOKING_RELOCATE,
  MANAGE_BOOKING_UPGRADE_ROOMTYPE,
} from '../../endpoints';

export const bookingGuestApiGw = createApi({
  reducerPath: 'bookingGuestApiGwService',
  tagTypes: ['bookingGuestApiGw'],
  baseQuery: baseQueryWithAuth(BASE_URL_API_GW),
  endpoints: builder => ({
    postGuestCheckIn: builder.mutation<
      unknown,
      { bookingId: string; guestId: string }
    >({
      query: body => ({
        url: BOOKING_GUEST_CHECKIN,
        method: Method.Post,
        body: body,
      }),
    }),
    postRoomRelocate: builder.mutation<
      void,
      { bookingId: string; roomId: string; forceDecreaseAvailability: boolean }
    >({
      query: body => ({
        url: MANAGE_BOOKING_RELOCATE,
        method: Method.Post,
        body: body,
      }),
    }),
    postManageBookingEarlyCheckIn: builder.mutation<
      void,
      { bookingId: string }
    >({
      query: body => ({
        url: MANAGE_BOOKING_EARLY_CHECK_IN,
        method: Method.Post,
        body: body,
      }),
    }),
    postManageBookingLateCheckOut: builder.mutation<
      void,
      { bookingId: string }
    >({
      query: body => ({
        url: MANAGE_BOOKING_LATE_CHECK_OUT,
        method: Method.Post,
        body: body,
      }),
    }),

    postBookingEarlyCheckIn: builder.mutation<void, { bookingId: string }>({
      query: body => ({
        url: BOOKING_EARLY_CHECK_IN,
        method: Method.Post,
        body: body,
      }),
    }),
    postBookingLateCheckOut: builder.mutation<void, { bookingId: string }>({
      query: body => ({
        url: BOOKING_LATE_CHECK_OUT,
        method: Method.Post,
        body: body,
      }),
    }),

    postUpgradeRoomType: builder.mutation<
      void,
      { bookingId: string; roomTypeId: string }
    >({
      query: body => ({
        url: MANAGE_BOOKING_UPGRADE_ROOMTYPE,
        method: Method.Post,
        body: body,
      }),
    }),
    postUpgradeRoomTypeInvoicing: builder.mutation<
      void,
      {
        bookingId: string;
        roomTypeId: number;
        useInternalAccountGuestId: number;
      }
    >({
      query: body => ({
        url: BOOKING_UPGRADE_ROOMTYPE,
        method: Method.Post,
        body: body,
      }),
    }),
  }),
});

export const {
  usePostGuestCheckInMutation,
  usePostRoomRelocateMutation,
  usePostManageBookingEarlyCheckInMutation,
  usePostManageBookingLateCheckOutMutation,
  usePostUpgradeRoomTypeMutation,
  usePostUpgradeRoomTypeInvoicingMutation,
  usePostBookingEarlyCheckInMutation,
  usePostBookingLateCheckOutMutation,
} = bookingGuestApiGw;
