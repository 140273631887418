import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuthCSV } from '../helpers';
import { BASE_URL_DATA_WALL, CURRENT_BOOKINGS } from '../../endpoints';
import { Method } from '../../types';
import { CurrentBookingsApiProps } from './booking-summary.types';

export const bookingSummaryApiCSV = createApi({
  reducerPath: 'bookingSummaryApiCSV',
  tagTypes: ['bookingSummaryCSV'],
  baseQuery: baseQueryWithAuthCSV(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    getBookingAllDataCSV: builder.query<string, CurrentBookingsApiProps>({
      query: ({
        HotelIds,
        Statuses,
        ChannelCodes,
        PaymentTypes,
        ...otherParams
      }) => {
        const params = new URLSearchParams();
        Object.entries(otherParams).forEach(([key, value]) => {
          if (value !== undefined) {
            params.append(key, value.toString());
          }
        });

        if (HotelIds && HotelIds.length) {
          HotelIds.forEach(id => params.append('HotelIds', id.toString()));
        }
        if (Statuses && Statuses.length) {
          Statuses.forEach(status =>
            params.append('Statuses', status.toString()),
          );
        }
        if (ChannelCodes && ChannelCodes.length) {
          ChannelCodes.forEach(code =>
            params.append('ChannelCodes', code.toString()),
          );
        }
        if (PaymentTypes && PaymentTypes.length) {
          PaymentTypes.forEach(type =>
            params.append('PaymentTypes', type.toString()),
          );
        }

        return {
          url: `${CURRENT_BOOKINGS}?${params.toString()}`,
          method: Method.Get,
        };
      },
      transformResponse: async (response: Response, meta, arg) => {
        const textResponse = await response.text();

        return {
          originalArg: arg,
          data: textResponse, 
        };
      },

      keepUnusedDataFor: 0.001, // Сохраняем кэш на минимальное время
    }),
  }),
});

export const { useGetBookingAllDataCSVQuery } = bookingSummaryApiCSV;
