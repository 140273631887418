import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { authActions } from 'modules/user/containers/auth-container/auth-container.slice';
import { HttpStatusCode, Method } from '../types';
import { AUTH_TOKEN_REFRESH_URL, BASE_URL } from '../endpoints';
import { StorageKey, storage } from 'shared/utils/storage_news';

interface RefreshTokenResponse {
  created: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
  roles: string[];
}

const baseQueryWithHeader = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const currentLanguage = localStorage.getItem('i18next') || 'ru-RU';
      const acceptLanguageHeader = `${currentLanguage},ru;q=0.9,en-US;q=0.8,en;q=0.7`;

      const { accessToken } = window.reduxStore.getState().auth;

      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Accept-Language', acceptLanguageHeader);
      headers.set('Accept', 'application/json');

      return headers;
    },
  });

const baseQueryWithHeaderCSV = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    responseHandler: async response => {
      const textData = await response; 

      return textData; 
    },
    prepareHeaders: headers => {
      const currentLanguage = localStorage.getItem('i18next') || 'ru-RU';
      const acceptLanguageHeader = `${currentLanguage},ru;q=0.9,en-US;q=0.8,en;q=0.7`;

      const { accessToken } = window.reduxStore.getState().auth;

      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Accept-Language', acceptLanguageHeader);
      headers.set('Accept', 'text/csv');

      return headers;
    },
  });

export const baseQueryWithAuth =
  (baseUrl: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
      const result = await baseQueryWithHeader(baseUrl)(args, api, extraOptions);

      const { refreshToken, isTokenRefreshProcessRunning } =
      window.reduxStore.getState().auth;

      try {
        if (
          result?.error?.status === HttpStatusCode.Unauthorized &&
        !isTokenRefreshProcessRunning
        ) {
        // предотвращает обновление рефреша в момент одновременного запуска разных миддлвар
          api.dispatch(authActions.setIsTokenRefreshProcessRunning(true));

          const refreshResult = await fetch(
            `${BASE_URL}${AUTH_TOKEN_REFRESH_URL}`,
            {
              method: Method.Post,
              body: JSON.stringify({
                refresh_token: refreshToken,
              }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken}`,
              },
            },
          );

          const resultData = (await refreshResult.json()) as RefreshTokenResponse;

          if (resultData) {
            const { access_token, refresh_token } = resultData;

            storage.setItem(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `token got ${new Date().toISOString()}` as any,
            new Date().toISOString(),
            );
            api.dispatch(authActions.setAccessToken(access_token));
            api.dispatch(authActions.setRefreshToken(refresh_token));
            api.dispatch(authActions.setIsTokenRefreshProcessRunning(false));
            storage.setItems({
              [StorageKey.AccessToken]: access_token,
              [StorageKey.RefreshToken]: refresh_token,
            });

            // часть запросов, которые были остановлены в момент обновления токена, рестартанут
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        }
      } catch (error) {
      // console.log('error', error);
        storage.setItem(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `token error ${new Date().toISOString()}` as any,
        new Date().toISOString(),
        );

        // жизнь рефреш-токена истекла, обновление невозможно
        // обновление токена /refresh с ошибкой 401
        api.dispatch(authActions.logout());
        storage.clear();

        setTimeout(() => {
          window.location.reload();
        }, 0);
      }

      return result;
    };

export const baseQueryWithAuthCSV =
  (baseUrl: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
      const result = await baseQueryWithHeaderCSV(baseUrl)(
        args,
        api,
        extraOptions,
      );

      const { refreshToken, isTokenRefreshProcessRunning } =
      window.reduxStore.getState().auth;

      try {
        if (
          result?.error?.status === HttpStatusCode.Unauthorized &&
        !isTokenRefreshProcessRunning
        ) {
        // предотвращает обновление рефреша в момент одновременного запуска разных миддлвар
          api.dispatch(authActions.setIsTokenRefreshProcessRunning(true));

          const refreshResult = await fetch(
            `${BASE_URL}${AUTH_TOKEN_REFRESH_URL}`,
            {
              method: Method.Post,
              body: JSON.stringify({
                refresh_token: refreshToken,
              }),
              headers: {
                'Content-Type': 'text/csv',
                Authorization: `Bearer ${refreshToken}`,
              },
            },
          );

          const resultData = (await refreshResult.json()) as RefreshTokenResponse;

          if (resultData) {
            const { access_token, refresh_token } = resultData;

            storage.setItem(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `token got ${new Date().toISOString()}` as any,
            new Date().toISOString(),
            );
            api.dispatch(authActions.setAccessToken(access_token));
            api.dispatch(authActions.setRefreshToken(refresh_token));
            api.dispatch(authActions.setIsTokenRefreshProcessRunning(false));
            storage.setItems({
              [StorageKey.AccessToken]: access_token,
              [StorageKey.RefreshToken]: refresh_token,
            });

            // часть запросов, которые были остановлены в момент обновления токена, рестартанут
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        }
      } catch (error) {
        storage.setItem(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        `token error ${new Date().toISOString()}` as any,
        new Date().toISOString(),
        );

        // жизнь рефреш-токена истекла, обновление невозможно
        // обновление токена /refresh с ошибкой 401
        api.dispatch(authActions.logout());
        storage.clear();

        setTimeout(() => {
          window.location.reload();
        }, 0);
      }

      return result;
    };

export const formatCurrency = (value: any) => {
  // Проверяем, является ли значение undefined или null, и возвращаем "0 ₽", если это так
  if (value == null) {
    return '0 ₽';
  }

  let valueStr = String(value);

  if (!valueStr.includes('.')) {
    valueStr += '.00';
  } else {
    const decimalPartIndex = valueStr.indexOf('.');
    const decimalPartLength = valueStr.length - decimalPartIndex - 1;
    if (decimalPartLength > 2) {
      valueStr = parseFloat(valueStr).toFixed(2);
    }
  }

  const [integerPart, decimalPart] = valueStr.split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' ',
  );

  return `${formattedIntegerPart}.${decimalPart} ₽`;
};
