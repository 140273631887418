import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import {
  BASE_URL_GUEST_SERIVCE,
  GUEST_DOCUMENT_INFO,
} from '../../endpoints';
import { Method } from '../../types';
import {
  GuestDocumentInfoResponse,
  GuestsDocumentInfoApiProps,
} from './guest-document-info.types';

export const guestDocumentInfoApi = createApi({
  reducerPath: 'guestDocumentInfoApi',
  tagTypes: ['guestDocumentInfo'],
  baseQuery: baseQueryWithAuth(BASE_URL_GUEST_SERIVCE),
  endpoints: builder => ({
    getGuestDocumentInfoData: builder.query<
      GuestDocumentInfoResponse[],
      GuestsDocumentInfoApiProps
    >({
      query: params => ({
        url: GUEST_DOCUMENT_INFO,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetGuestDocumentInfoDataQuery } = guestDocumentInfoApi;
