export enum StorageKey {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  ExpiresIn = 'expiresIn',
  Roles = 'roles',
  isTokenRefreshProcessRunning = 'isTokenRefreshProcessRunning',
  BookingSummary = 'bookingSummary',
  HotellAll = 'hotellAll',
}

type NonUndefined<T> = T extends undefined ? never : T;
type OwnStorage = Repository | Omit<Storage, 'length' | 'key'>;

interface Repository<K extends string = string> {
  setItem: <T>(key: K, value: NonUndefined<T>) => void;
  setItems: (items: Partial<Record<K, unknown>>) => void;
  getItem: <T>(key: K) => T | null;
  removeItem: (key: K) => void;
  clear: VoidFunction;
}

export const createStorage = <K extends string = string>(
  newStorage: OwnStorage = sessionStorage,
): Repository<K> => {
  const setItem = <T>(key: K, value: NonUndefined<T>): void => {
    newStorage.setItem(key, JSON.stringify(value));
  };

  const setItems = (items: Partial<Record<string, unknown>>): void => {
    Object.entries(items).forEach(([key, value]) => {
      setItem(key as K, value);
    });
  };

  const getItem = <R>(key: K): R | null => {
    const data = newStorage.getItem(key) ?? '';

    try {
      return JSON.parse(data);
    } catch {
      return null;
    }
  };

  const removeItem = (key: K): void => {
    newStorage.removeItem(key);
  };

  const clear = (): void => {
    newStorage.clear();
  };

  return { setItem, setItems, getItem, removeItem, clear };
};

export const storage = createStorage<StorageKey>(localStorage);
