import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import {
  BASE_URL_IDENTITY_SERVICE,
  BLOCK_USER,
  GET_USER_BY_ID,
  UNBLOCK_USER,
  USER_INFO,
} from '../../endpoints';
import { Method } from '../../types';
import { GetUserResponse, UserInfoApiProps, UserInfoResponse } from './user-info.types';

export const userInfoApi = createApi({
  reducerPath: 'userInfoApi',
  tagTypes: ['userInfo'],
  baseQuery: baseQueryWithAuth(BASE_URL_IDENTITY_SERVICE),
  endpoints: builder => ({
    getUserInfoData: builder.query<UserInfoResponse, UserInfoApiProps>({
      query: params => ({
        url: USER_INFO,
        method: Method.Get,
        params: params,
      }),
    }),
    getUserData: builder.query<GetUserResponse, { UserId: string }>({
      query: params => ({
        url: GET_USER_BY_ID,
        method: Method.Get,
        params: params,
      }),
    }),
    postBlockUser: builder.mutation<unknown, { userId: string }>({
      query: body => ({
        url: BLOCK_USER,
        method: Method.Post,
        body,
      }),
    }),
    postUnBlockUser: builder.mutation<unknown, { userId: string }>({
      query: body => ({
        url: UNBLOCK_USER,
        method: Method.Post,
        body,
      }),
    }),
  }),
});

export const {
  useGetUserInfoDataQuery,
  usePostBlockUserMutation,
  usePostUnBlockUserMutation,
  useGetUserDataQuery
} = userInfoApi;
