import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_DATA_WALL, HOTELS_ALL } from '../../endpoints';
import { Method } from '../../types';

import { HotelsAllResponse } from './hotels-all.types';

export const hotelsAllApi = createApi({
  reducerPath: 'hotelsAllApi',
  tagTypes: ['hotelsAll'],
  baseQuery: baseQueryWithAuth(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    geHotelsAllData: builder.query<HotelsAllResponse, unknown>({
      query: params => ({
        url: HOTELS_ALL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGeHotelsAllDataQuery } = hotelsAllApi;
