export const translationsDictionary = {
  'База гостей': 'Guest base',
  'Гости': 'Guests',
  'Бронирования': 'Reservations',
  'Выйти': 'Logout',
  'прогноз': 'forecast',
  'Тариф': 'Tariff',
  'Сумма проживания': 'Amount of stay',
  'Сумма услуг': 'Amount of services',
  'Оплачено': 'Paid',

  'Назначение комнаты': 'Room assignment',
  'Привязать к бронированию': 'Link to reservation',
  'Добавить комментарий к бронированию': 'Add comment to reservation',
  'Неоплачен': 'Unpaid',
  'Добавление гостя': 'Adding guest',
  'Введите ФИО, номер или код гостя': 'Enter name, number, or guest code',
  'Подтверждение': 'Confirmation',
  'Точно ли вы хотите изменить статус гостя?': 'Are you sure you want to change the guest status?',
  'Добавление комментария': 'Adding comment',
  'Добавить комментарий': 'Add comment',
  'Выгрузить в CSV': 'Export to CSV',
  'Показать/Скрыть колонки': 'Show/Hide columns',
  'Элементов на странице': 'Items per page',
  'Переселение': 'Relocation',
  'Результаты не найдены': 'No results found',
  'Комната обязательна для выбора.': 'The room is required.',
  'Комментарий обязателен для заполнения.': 'Comments are required.',
  'Поле не может быть пустым': 'Field cannot be empty',
 
  'Заезд': 'Check-in',
  'Выезд': 'Check-out',
  'Дата бронирования': 'Booking Date',
  'Ночей': 'Nights',
  'Оплачен': 'Paid',
  'Ранний заезд': 'Early check-in',
  'Поздний выезд': 'Late check-out',
  'Да': 'Yes',
  'Нет': 'No',
  'Комментарий': 'Comment',
  'Дата заселения': 'Check-in Date',
  'Дата отмены': 'Cancellation Date',
  'Комментарий отмены': 'Cancellation Comment',
  'Отель': 'Hotel',
  'Статус': 'Status',
  'Основной Гость': 'Main Guest',
  'Телефон': 'Phone',
  'Email': 'Email',
  'Гостей': 'Guests',
  'Гость': 'Guest',
  'Канал': 'Channel',
  'Внешний номер': 'External number',
  'Редактировать': 'Edit',
  'Номер комнаты': 'Room number',
  'Категория номера': 'Room category',
  'Комментарий гостя': 'Guest comment',
  'Внешний номер бронирования': 'External booking number',
  'Причина отмены': 'Reason for cancellation',
  'Всего гостей': 'Total guests',
  'Завтрак': 'Breakfast',
  'Доступ запрещен': 'Access denied',
  'У вас нет прав доступа на редактирование': 'You do not have edit access',
  'У вас нет прав доступа на просмотр': 'You do not have view access',
  'Неизвестная ошибка': 'Unknown error',
  'У вас нет прав доступа': 'You do not have access',
  'Ошибка запроса': 'Request error',
  'Запрос выполнен успешно': 'Request completed successfully',
  'Успешно': 'Successfully',

  'В этом отчете можно найти все бронирования, когда-либо созданные в системе. Используйте его для поиска нужного бронирования или формирования списка по определенным параметрам.': 'In this report, you can find all the bookings ever created in the system. Use it to search for the necessary booking or to generate a list based on specific parameters.',
  'Список гостей': 'Guest List',
  'ОТКРЫТЬ': 'OPEN',
  'Отчет по броням': 'Bookings Report',
  'Отчет по бронированию': 'Booking Report',
  'По номеру бронирования, фамилии и номеру телефона': 'By booking number, last name, and phone number',
  'Отели': 'Hotels',
  'Выберите': 'Select',
  'По датам': 'By Dates',
  'Все': 'All',
  'Способы оплаты': 'Payment Methods',
  'Номер': 'Number',
  'Комната': 'Room',
  'Доступная для выбора': 'Available for selection',
  'Ничего не найдено': 'Nothing found',
  'Загрузка...': 'Loading...',
  'Новая категория': 'New category',
  'Доступны только не привязанные к бронированиям комнаты': 'Only rooms not linked to bookings are available',
  'Назначить номер': 'Assign room',
  'Добавить заметку': 'Add note',
  'Заметка к бронированию': 'Booking note',
  'Поиск гостя': 'Guest search',
  'Новая заметка': 'New note',
  'Комментарий должен быть не менее 5 символов.': 'The comment must be at least 5 characters long.',
  'Гость оплачивает в Яндекс.Путешествия. Оплата за проживание будет перечислена от Яндекс.Путешествий через 3-5 рабочих дней после выезда бронирования. Всю информацию об оплате рекомендуем проверять в личном кабинете Яндекс.Путешествий': 'The guest pays via Yandex.Travel. The payment for the stay will be transferred from Yandex.Travel within 3-5 business days after check-out. We recommend checking all payment details in your Yandex.Travel account.',
  'Заметки к бронированию': 'Booking notes',
  'Заметки о гостях': 'Guest notes',
  'Основное': 'Main',
  'Фолио': 'Folio',
  'Статус бронирования': 'Booking status',
  'Заселить всех гостей': 'Check in all guests',
  'Установить незаезд': 'Mark as no-show',
  'Отменить бронирование': 'Cancel booking',
  'Проживание': 'Stay',
  'Назначить комнату': 'Assign room',
  'Апгрейд категории': 'Category upgrade',
  'Другое': 'Other',
  'Добавить услугу': 'Add service',
  'Задача SpaceKeeper': 'SpaceKeeper task',
  'Подтвердите действие': 'Confirm action',
  'Заселить бронирование?': 'Check in booking?',
  'Все гости в этом бронировании будут зарегистрированы в отеле и получат доступ к номеру.': 'All guests in this booking will be registered at the hotel and gain access to the room.',
  'Продолжить': 'Continue',
  'Отметить как незаезд?': 'Mark as no-show?',
  'Это бронирование станет недоступно. Гости не смогут заселиться в отель.': 'This booking will become unavailable. Guests will not be able to check into the hotel.',
  'Это действие нельзя отменить.': 'This action cannot be undone.',
  'Отменить бронирование?': 'Cancel booking?',
  'Это бронирование будет недоступно. Гости не смогут заселиться в отель.': 'This booking will become unavailable. Guests will not be able to check into the hotel.',
  'Выставить счет': 'Issue invoice',
  'Комплементарно': 'Complimentary',
  'Оплата': 'Payment',
  'Укажите способ оплаты.': 'Specify the payment method.',
  'Недоступно: Бесплатно по программе лояльности': 'Unavailable: Free under loyalty program',
  'Тип': 'Type',
  'Услуга': 'Service',
  'Вручную': 'Manually',
  'Время заезда': 'Check-in time',
  'Будет установлено согласно условиям отеля': 'Will be set according to hotel policy',
  'Время выезда': 'Check-out time',
  'Установите вручную. Изменения не будут учтены в фолио.': 'Set manually. Changes will not be reflected in the folio.',
  'Отчет о бронях': 'Booking report',
  'Тип комнаты': 'Room Type',
  'Выселить всех': 'Evict all',
  'Переселить': 'Relocate',
  'Выселить всех гостей?': 'Evict all guests?',
  'Бронирование будет закрыто, и гости потеряют доступ в номер.': 'The booking will be closed, and guests will lose access to the room.',
  'Включен': 'Enabled',
  'Дата заезда': 'Check-in date',

  'Дата создания': 'Created Date',
  'Дата выезда': 'Check-Out Date',
  'Основной гость': 'Main Guest',
  'Канал продаж': 'Sales Channel',
  'Сумма': 'Amount',
  'Заселен': 'Check-In',
  'Выселен': 'Check-Out',
  'Заселить': 'Check-In',
  'Выселить': 'Check-Out',
  'Ожидается': 'Expected',
  'Отменен': 'Cancelled',
  'Незаезд': 'DNA',
  'из': 'of',
  'Все статусы': 'All statuses',
  'Поиск': 'Search',
  'Новое': 'New',
  'Заезжают сегодня': 'Arriving Today',
  'Отмененные': 'Cancelled',
  'Период': 'Period',
  'Укажите диапазон': 'Specify range',
  'От': 'From',
  'Применить': 'Apply',
  'До': 'Before',
  'Начальная дата': 'Start Date',
  'Конечная дата': 'End Date',
  'Все бронирования': 'All bookings',
  'Шахматка': 'Booking grid',
  'Проживают': 'Reside',
  'Отменена за ': 'Cancelled due to',
  'Нет данных для отображения': 'No records to display',

  'Тип платежа': 'Payment type',
  'Статус ПЛ': 'Status PL',
  'Взрослых всего ': 'Total adults',
  'Детей всего': 'Total children',
  'Гостей в бронировании ': 'Guests in booking',
  'Тип платежа ': 'Payment type',

  'Раздел': 'Section',
  'Домашняя страница': 'Home page',
  'Новый гость': 'New guest',
  'Фамилия': 'Last Name',
  'Имя': 'First Name',
  'Отчество': 'MiddleName',
  'Номер телефона': 'Phone Number',
  'Уровень лояльности': 'Loyalty Level',
  'Удален': 'Deleted',
  'Верифицирован': 'Verified',
  'Пол': 'Gender',
  'Женский': 'Female',
  'Мужской': 'Male',
  'Дата рождения': 'Date of Birth',
  'Гражданство': 'Nationality',
  'Кол-во ночей': 'Number of Nights',
  'Кол-во посещений': 'Number of Visits',
  'РФ': 'Russian Federation',
  'США': 'USA',
  
  'Разблокировать пользователя': 'Unblock user',
  'Подтвердите разблокировку пользователя вводом номера телефона:': 'Confirm user unblocking by entering the phone number:',

  'Обнуление баланса': 'Balance reset',
  'Вы уверены что хотите обнулить баланс внутреннего счета?': 'Are you sure you want to reset your internal account balance?',
  'Отменить': 'Cancel',
  'Подтвердить': 'Confirm',
  'Блокировка гостя': 'Blocking a guest',
  'Статус программы лояльности': 'Loyalty program status',
  
  'Баланс внутреннего счёта': 'Internal account balance',
  'Текущий баланс': 'Current balance',
  'Пополнить': 'Top up',
  'Новый статус': 'New status',
  'Списать': 'Write off',
  'Введите сумму пополнения': 'Enter the top-up amount',
  'Введите сумму списания': 'Enter the deduction amount',

  'Изменить номер телефона': 'Change phone number',
  'Текущий номер телефона': 'Current phone number',
  'Поле обязательное': 'Field required',
  'Новый номер телефона': 'New phone number',
  'Сохранить': 'Save',
  'Причина': 'Cause',
  
  'Подтверждение номера:': 'Number confirmation:',
  'Вы уверены, что хотите удалить этот комментарий?': 'Are you sure you want to delete this comment?',

  'Заметка о госте': 'Guest Note',
  'Осталось имволов: ': 'Characters left: ',
  'Номер не совпадает': 'Number does not match',
  'Удаление': 'Removal',
  'Подтвердите удаление профиля гостя вводом номера телефона:': 'Confirm deletion of the guest profile by entering your phone number:',
  
  'Добавить гостя': 'Add a guest',
  'Неверный формат email': 'Неверный формат email',
  'Номер телефона не должен содержать букв': 'The phone number must not contain letters',
  'Номер телефона обязателен': 'Phone number is required',
  'Неверный формат номера телефона. Используйте только цифры.': 'Invalid phone number format. Use numbers only.',
  'Номер телефона должен содержать 11 цифр': 'Phone number must contain 11 digits',

  'Блокировка пользователя': 'Blocking a user',
  'Подтвердите блокировку пользователя вводом номера телефона:': 'Confirm blocking the user by entering the phone number:',

  'Создан': 'Created',
  'Обновлен': 'Updated',
  'Был активен': 'Was active',
  'Код': 'Code',
  'Заблокирован': 'Blocked',
  'Документы': 'Documents',
  'Проверены': 'Verified',
  'Не проверены': 'Not verified',
  'Действия': 'Actions',
  'Изменить карточку': 'Change card',
  'Смена номера телефона': 'Changing your phone number',
  'Обнулить баланс внутр. счета': 'Reset internal account balance',
  'Посмотреть фото': 'View photo',
  'Удалить профиль': 'Delete profile',
  'Личные данные': 'Personal information',
  'Текущий статус': 'Current status',

  'Дата': 'Date',
  'Отмена': 'Cancel',
  'Событие': 'Event',
  'Комментарии': 'Comments',
  'Начислено': 'Credited',
  'Списано': 'Debited',
  'Бронирование': 'Booking',
  'Даты': 'Dates', 
  'Покупка по внутреннему счету': 'Internal account purchase',
  'Первое бронирование': 'First booking',
  'Отмена бронирования': 'Booking cancellation',
  'Ручное изменение счета': 'Manual account update',
  'Назначение статуса при регистрации': 'Status assigned upon registration',
  'Изменения статуса по количеству визитов': 'Status change based on visits',
  'Изменения статуса по количеству ночей': 'Status change based on nights',
  'Ручное изменение статуса': 'Manual status update',
  'Нет доступных данных для отображения.': 'No data available for display.',

  'Редактировать гостя': 'Change guest',
  'Карточка гостя': 'Guest card',
  'У вас нет прав доступа на просмотр этой информации': 'You do not have permission to view this information',
  'Пользователь': 'User',
  'Актуальные заметки': 'Current Notes',
  'Автор': 'Author',
  'Актуальных заметок нет': 'There are no current notes',
  'Данных о бронировании нет': 'Booking data not available',

  'Актуальные бронирования': 'Current Reservations',
  'Проживаний всего': 'Total Stays',
  'Статус в программе лояльности': 'Loyalty Program Status',
  'Заметки': 'Notes',
  'История статусов': 'Status history',
  'Внутренний счет': 'Internal account',
  'Программа лояльности': 'Loyalty program',
  'Итого': 'Total',

  'Баланс': 'Balance',
  'Редактировать номер телефона': 'Edit Phone Number',
  'Добавить': 'Add',
  'Удалить': 'Delete',
  'История': 'History',
  'Изменить': 'Change'
};
