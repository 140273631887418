import { createApi } from '@reduxjs/toolkit/query/react';
import { AllRoomApiProps, AllRoomResponse, HotelApiProps, HotelResponse, RoomApiProps, RoomResponse } from './hotel-siervice.types';
import {
  BASE_URL_HOTEL_SERVICE,
  GET_BY_ID_HOTEL_URL,
  ROOM_GET_BY_HOTEL_ID,
  ROOM_GET_BY_ID,
} from 'app/providers/store/endpoints';
import { baseQueryWithAuth } from '../../helpers';
import { Method } from 'app/providers/store/types';

export const hotelServiceApi = createApi({
  reducerPath: 'hotelServiceApi',
  tagTypes: ['hotelService'],
  baseQuery: baseQueryWithAuth(BASE_URL_HOTEL_SERVICE),
  endpoints: builder => ({
    getHotelData: builder.query<HotelResponse, HotelApiProps>({
      query: params => ({
        url: GET_BY_ID_HOTEL_URL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
    getRoomIdData: builder.query<RoomResponse, RoomApiProps>({
      query: params => ({
        url: ROOM_GET_BY_ID,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0,
    }),
    getAllRoom: builder.query<AllRoomResponse, AllRoomApiProps>({
      query: params => ({
        url: ROOM_GET_BY_HOTEL_ID,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetHotelDataQuery,useGetRoomIdDataQuery,useGetAllRoomQuery } = hotelServiceApi;
