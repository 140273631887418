import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_GUEST_SERIVCE, GUEST_INFO } from '../../endpoints';
import { Method } from '../../types';
import { GuestInfoResp, GuestsInfoProps } from './guest-info.types';

export const guestInfoApi = createApi({
  reducerPath: 'guestInfoApi',
  tagTypes: ['guestInfo'],
  baseQuery: baseQueryWithAuth(BASE_URL_GUEST_SERIVCE),
  endpoints: builder => ({
    getGuestInfoData: builder.query<GuestInfoResp[], GuestsInfoProps>({
      query: ({ Ids }) => {
        const params = new URLSearchParams();

        Object.entries(Ids).forEach(([key, value]) => {
          if (value !== undefined) {
            params.append(key, value.toString());
          }
        });

        if (Ids && Ids.length) {
          Ids.forEach(type => params.append('Ids', type.toString()));
        }

        return {
          url: `${GUEST_INFO}?${params.toString()}`,
          method: Method.Get,
        };
      },
      // }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetGuestInfoDataQuery } = guestInfoApi;
