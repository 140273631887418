let subURL;
switch (__REDIRECT_URI__) {
  case 'https://pms.dev.apeironspace.ru/':
    subURL = 'https://gw.dev.';
    break;
  case 'https://pms.test.apeironspace.ru/':
    subURL = 'https://gw.test.';
    break;
  case 'http://localhost:4000':
    subURL = 'https://gw.test.';
    break;
  default:
    subURL = 'https://gw.test.';
}

// console.log('__REDIRECT_URI__', __REDIRECT_URI__);

export const BASE_URL = `${subURL}apeironspace.ru`;
export const BASE_URL_API_GW = `${BASE_URL}/api/v1`;
export const AUTH_TOKEN_URL = '/identity-service/api/v1/Auth/Login';
export const AUTH_TOKEN_REFRESH_URL =
  '/identity-service/api/v1/Auth/RefreshToken';
export const BASE_URL_DATA_WALL = `${BASE_URL}/datawall-service/api/v1`;
export const BASE_URL_GUEST_SERIVCE = `${BASE_URL}/guest-service/api/v1`;
export const BASE_URL_PROMO_SERVICE = `${BASE_URL}/promo-service/api/v1`;
export const BASE_URL_IDENTITY_SERVICE = `${BASE_URL}/identity-service/api/v1`;
export const BASE_URL_BOOKING_SERVICE = `${BASE_URL}/booking-service/api/v1`;
export const BASE_URL_HOTEL_SERVICE = `${BASE_URL}/hotel-service/api/v1`;

export const BOOKING_COMMENT_URL = '/BookingComment/GetByBookingId';
export const BOOKING_COMMENT_CREATE_URL = '/BookingComment/Create';
export const BOOKING_COMMENT_DELETE_URL = '/BookingComment/Delete';
export const BOOKING_GUEST_CHECKIN = '/BookingGuest/CheckIn';
export const BOOKING_GUEST_CHECKOUT = '/BookingGuest/CheckOut';
export const BOOKING_GUEST_REMOVE = '/BookingGuest/Remove';
export const BOOKING_GUEST_ADD = '/BookingGuest/Add';
export const GUESTS_ALL_URL = '/Guests/GetGuests';
export const GET_BY_ID_URL = '/Booking/GetById';
export const GET_BY_ID_HOTEL_URL = '/Hotel/GetById';
export const GUEST_INFO = '/Guest/GetByIds';
export const USER_INFO = '/admin/GetUserByGuestId';
export const GUEST_DOCUMENT_INFO = '/RegistrationForm/ByGuestId';
export const InternalAccount_GetByGuestId_URL = '/InternalAccount/GetByGuestId';
export const BOOKING_LIST = '/InternalAccount/GetByGuestId';
export const COMMENT_LIST_URL = '/GuestComment/GetByGuestId';
export const LOYALTY_GUEST_URL = '/Loyalty/GetByGuestId';
export const BOOKING_SUMMARY_URL = '/Bookings/GetBookingsSummary';
export const CURRENT_BOOKINGS = '/Bookings/GetBookings';
export const HOTELS_ALL = '/Hotels/GetHotels';
export const GUEST_UPDATE_URL = '/Guest/Update';
export const GUEST_CREATE_COMMENT_URL = '/GuestComment/Create';
export const GUEST_DELETE_COMMENT_URL = '/GuestComment/Delete';
export const UPDATE_LOYALTY_LEVEL_URL = '/Loyalty/UpdateLoyaltyLevelByGuestId';
export const UPDATE_NUMBER_PHONE_URL = '/manage/ManageGuest/UpdatePhoneNumber';
export const MANAGE_BOOKING_RELOCATE = '/manage/ManageBooking/Relocate';
export const MANAGE_BOOKING_SETROOM = '/manage/ManageBooking/SetRoom';
export const LOYALTY_HISTORY = '/History/GetLoyaltyHistory';
export const INTERNAL_ACCOUNT = '/History/GetInternalAccountHistory';
export const INTERNAL_ACCOUNT_UPDATE = '/InternalAccount/Update';
export const INTERNAL_ACCOUNT_ANNULATE = '/InternalAccount/Annulate';
export const GUEST_DELETE = '/guest/Delete';
export const GUEST_CREATE = '/guest/Create';
export const BLOCK_USER = '/admin/BlockUser';
export const UNBLOCK_USER = '/admin/UnBlockUser';
export const GET_USER_BY_ID = '/admin/GetUserById';

export const CHANNEL_GETALL = '/Channel/GetAll';
export const ROOM_GET_BY_ID = '/Room/GetById';
export const ROOM_GET_BY_HOTEL_ID = '/Room/GetByHotelId';
