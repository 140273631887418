import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import {
  BASE_URL_BOOKING_SERVICE,
  BOOKING_COMMENT_CREATE_URL,
  BOOKING_COMMENT_DELETE_URL,
  BOOKING_COMMENT_URL,
  BOOKING_GUEST_ADD,
  BOOKING_GUEST_CHECKOUT,
  BOOKING_GUEST_REMOVE,
  CHANNEL_GETALL,
  GET_BY_ID_URL,
} from '../../endpoints';
import { Method } from '../../types';
import {
  BookingApiProps,
  BookingCommentApiProps,
  BookingCommentResponse,
  BookingResponse,
  ChannelAllResponse,
} from './booking-siervice-channel.types';

export const bookingServiceChannelApi = createApi({
  reducerPath: 'bookingServiceChannelApi',
  tagTypes: ['bookingServiceChannel'],
  baseQuery: baseQueryWithAuth(BASE_URL_BOOKING_SERVICE),
  endpoints: builder => ({
    getChannelAllData: builder.query<ChannelAllResponse[], unknown>({
      query: params => ({
        url: CHANNEL_GETALL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
    getBookingData: builder.query<BookingResponse, BookingApiProps>({
      query: params => ({
        url: GET_BY_ID_URL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
    getBookingCommentData: builder.query<
      BookingCommentResponse[],
      BookingCommentApiProps
    >({
      query: params => ({
        url: BOOKING_COMMENT_URL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
    postBookingCommentCreate: builder.mutation<
      unknown,
      { bookingId: string; comment: string }
    >({
      query: body => ({
        url: BOOKING_COMMENT_CREATE_URL,
        method: Method.Post,
        body: body,
      }),
    }),

    deleteBookingCommentDelete: builder.mutation<
      unknown,
      { bookingCommentId: string }
    >({
      query: body => ({
        url: BOOKING_COMMENT_DELETE_URL,
        method: Method.Delete,
        body: body,
      }),
    }),
    postGuestCheckOut: builder.mutation<
      unknown,
      { bookingId: string; guestId: string }
    >({
      query: body => ({
        url: BOOKING_GUEST_CHECKOUT,
        method: Method.Put,
        body: body,
      }),
    }),
    postGuestRemove: builder.mutation<
      unknown,
      { bookingId: string; guestIds: [string] }
    >({
      query: body => ({
        url: BOOKING_GUEST_REMOVE,
        method: Method.Post,
        body: body,
      }),
    }),

    postAddGuestBooking: builder.mutation<
      unknown,
      {
        bookingId: string;
        guests: [
          {
            guestId: 0;
            isMain: boolean;
            isHidden: boolean;
            isSilent: boolean;
          },
        ];
      }
    >({
      query: body => ({
        url: BOOKING_GUEST_ADD,
        method: Method.Post,
        body: body,
      }),
    }),
  }),
});

export const {
  useGetChannelAllDataQuery,
  useGetBookingDataQuery,
  useGetBookingCommentDataQuery,
  usePostBookingCommentCreateMutation,
  useDeleteBookingCommentDeleteMutation,
  usePostAddGuestBookingMutation,
  usePostGuestCheckOutMutation,
  usePostGuestRemoveMutation,
} = bookingServiceChannelApi;
