import { createSlice } from '@reduxjs/toolkit';
import { authApi } from 'app/providers/store/api/auth/auth';
import { StorageKey, storage } from 'shared/utils/storage_news';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  roles: string | null;
  expiresIn: number | null;
  isLoading: boolean;
  isTokenRefreshProcessRunning: boolean;
}

const initialState: AuthState = {
  accessToken: storage.getItem(StorageKey.AccessToken) || null,
  refreshToken: storage.getItem(StorageKey.RefreshToken) || null,
  expiresIn: storage.getItem(StorageKey.ExpiresIn) || null,
  roles: storage.getItem(StorageKey.Roles) || null,
  isLoading: false,
  isTokenRefreshProcessRunning:
    storage.getItem(StorageKey.isTokenRefreshProcessRunning) || false,
};

export const authSlice = createSlice({
  name: 'authApi',
  initialState,
  reducers: {
    logout: state => {
      storage.clear(); // Очищаем локальное хранилище при выходе из системы
      state.accessToken = null;
      state.refreshToken = null;
      state.expiresIn = null;
      state.roles = null;
      state.isTokenRefreshProcessRunning = false;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      storage.setItem(StorageKey.AccessToken, action.payload); // Обновляем локальное хранилище при изменении токена
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      storage.setItem(StorageKey.RefreshToken, action.payload); // Обновляем локальное хранилище при изменении токена обновления
    },
    setExpiresIn: (state, action) => {
      state.expiresIn = action.payload;
      storage.setItem(StorageKey.ExpiresIn, action.payload); // Обновляем локальное хранилище при изменении срока действия
    },
    setRoles: (state, action) => {
      state.roles = action.payload[0];
      storage.setItem(StorageKey.Roles, action.payload); // Обновляем локальное хранилище при изменении срока действия
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsTokenRefreshProcessRunning: (state, action) => {
      state.isTokenRefreshProcessRunning = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints?.token.matchFulfilled,
      (state, { payload: { access_token, refresh_token, expires_in,roles } }) => {
        state.accessToken = access_token;
        state.refreshToken = refresh_token;
        state.expiresIn = expires_in;
        state.roles = roles[0];
        state.isLoading = false; // Устанавливаем isLoading в false после успешного получения токена
        state.isTokenRefreshProcessRunning = false;
        storage.setItem(StorageKey.AccessToken, access_token); // Обновляем локальное хранилище при успешном получении токена
        storage.setItem(StorageKey.RefreshToken, refresh_token); // Обновляем локальное хранилище при успешном получении токена обновления
        storage.setItem(StorageKey.ExpiresIn, expires_in); // Обновляем локальное хранилище при успешном получении срока действия
        storage.setItem(StorageKey.Roles, roles); // Обновляем локальное хранилище при успешном получении срока действия
        storage.setItem(StorageKey.isTokenRefreshProcessRunning, false); // Устанавливаем isTokenRefreshProcessRunning в false после успешного получения токена
      },
    );
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
