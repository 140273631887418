import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_GUEST_SERIVCE, COMMENT_LIST_URL } from '../../endpoints';
import { Method } from '../../types';
import { CommentListApiProps, CommentListResponse } from './comment-list.types';

export const commentListApi = createApi({
  reducerPath: 'commentListApi',
  tagTypes: ['commentList'],
  baseQuery: baseQueryWithAuth(BASE_URL_GUEST_SERIVCE),
  endpoints: builder => ({
    getCommentListData: builder.query<CommentListResponse[], CommentListApiProps>(
      {
        query: params => ({
          url: COMMENT_LIST_URL,
          method: Method.Get,
          params: params,
        }),
      },
    ),
  }),
});

export const { useGetCommentListDataQuery } = commentListApi;
