import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_PROMO_SERVICE, INTERNAL_ACCOUNT, LOYALTY_GUEST_URL, LOYALTY_HISTORY, UPDATE_LOYALTY_LEVEL_URL } from '../../endpoints';
import { Method } from '../../types';
import { InternalAccountApiProps, InternalAccountResponse, LoyaltyGuestApiProps, LoyaltyGuestResponse, LoyaltyHistoryApiProps, LoyaltyHistoryResponse, LoyaltyUpdateApiProps } from './loyalty-guest.types';

export const loyaltyGuestApi = createApi({
  reducerPath: 'loyaltyGuestApi',
  tagTypes: ['loyaltyGuest'],
  baseQuery: baseQueryWithAuth(BASE_URL_PROMO_SERVICE),
  endpoints: builder => ({
    getLoyaltyGuestData: builder.query<
      LoyaltyGuestResponse,
      LoyaltyGuestApiProps
    >({
      query: params => ({
        url: LOYALTY_GUEST_URL,
        method: Method.Get,
        params: params,
      }),
    }),
    updateLoyaltyLevel: builder.mutation<
      void,
      LoyaltyUpdateApiProps
    >({
      query: body => ({
        url: UPDATE_LOYALTY_LEVEL_URL,
        method: Method.Put,
        body,
      }),
    }),
    getLoyaltyHistiry: builder.query<
    LoyaltyHistoryResponse[],
    LoyaltyHistoryApiProps
    >({
      query: params => ({
        url: LOYALTY_HISTORY,
        method: Method.Get,
        params:params,
      }),
    }),
    getInternalAccount: builder.query<
    InternalAccountResponse[],
    InternalAccountApiProps
    >({
      query: params => ({
        url: INTERNAL_ACCOUNT,
        method: Method.Get,
        params:params,
      }),
    }),
  }),
});

export const { useGetLoyaltyGuestDataQuery, useUpdateLoyaltyLevelMutation,useGetLoyaltyHistiryQuery,useGetInternalAccountQuery } = loyaltyGuestApi;
