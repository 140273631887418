import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { showNotification } from '@mantine/notifications';
import { t } from 'i18next';

const apiMiddleware: Middleware = () => next => action => {
  if (isRejectedWithValue(action) && action?.payload?.originalStatus != '200') {
    const error = action.payload;
    const status = error?.status;
    console.log('error', error);
    const errorMessage =
      error?.data?.message || error?.data?.title || t('Неизвестная ошибка');

    // Определяем тип запроса на основе 'action.type'
    const isMutation = action.type.includes('/executeMutation');
    const isQuery = action.type.includes('/executeQuery');
    if (status === 422) {
      return next(action);
    }

    if (status === 403) {
      if (isMutation) {
        showNotification({
          title: t('Доступ запрещен'),
          message: t('У вас нет прав доступа на редактирование'),
          color: 'red',
        });
      } else if (isQuery) {
        // showNotification({
        //   title: 'Доступ запрещен',
        //   message: 'У вас нет прав доступа на просмотр',
        //   color: 'red',
        // });
        console.log('У вас нет прав доступа на просмотр');
      } else {
        showNotification({
          title: t('Доступ запрещен'),
          message: t('У вас нет прав доступа'),
          color: 'red',
        });
      }
    } else {
      // Показать уведомление об ошибке для других статусов
      showNotification({
        title: t('Ошибка запроса'),
        message: errorMessage,
        color: 'red',
      });
    }
  } else if (
    action.type.includes('/executeMutation') &&
    !isRejectedWithValue(action) &&
    action.meta.requestStatus !== 'pending' &&
    !action.type.includes('authApi')
  ) {
    // Отобразить уведомление об успешном выполнении для действий типа "mutation"
    showNotification({
      title: t('Успешно'),
      message: t('Запрос выполнен успешно'),
      color: 'green',
    });
  } else if (
    action?.payload?.originalStatus &&
    action?.payload?.originalStatus == '200'
  ) {
    showNotification({
      title: t('Успешно'),
      message: t('Запрос выполнен успешно'),
      color: 'green',
    });
  }

  return next(action);
};

export default apiMiddleware;
