export enum Routes {
  Home = '/home',
  Auth = '/',
  Guest = 'guest',
  Reservations = '/reservations',
}

export const routes = {
  home: Routes.Home,
  auth: Routes.Auth,

  // Страница гостя
  guests: Routes.Guest,
  guest: `/${Routes.Guest}/:id`,

  // Страница бронирований
  reservations: Routes.Reservations,
};
