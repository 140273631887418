import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import {
  BASE_URL_DATA_WALL,
  BOOKING_SUMMARY_URL,
  CURRENT_BOOKINGS,
} from '../../endpoints';
import { Method } from '../../types';
import {
  BookingSummaryApiProps,
  BookingSummaryResponse,
  CurrentBookingsApiProps,
  CurrentBookingsResponse,
} from './booking-summary.types';

export const bookingSummaryApi = createApi({
  reducerPath: 'bookingSummaryApi',
  tagTypes: ['bookingSummary'],
  baseQuery: baseQueryWithAuth(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    getBookingSummaryData: builder.query<
      BookingSummaryResponse[],
      BookingSummaryApiProps
    >({
      query: ({ GuestId, HotelIds }) => {
        const params = new URLSearchParams();
        params.append('GuestId', GuestId);
        HotelIds.forEach(id => params.append('HotelIds', id));

        return {
          url: `${BOOKING_SUMMARY_URL}?${params.toString()}`,
          method: Method.Get,
        };
      },
      keepUnusedDataFor: 0.001,
    }),
    getBookingAllData: builder.query<
      CurrentBookingsResponse,
      CurrentBookingsApiProps
    >({
      query: ({
        HotelIds,
        Statuses,
        ChannelCodes,
        PaymentTypes,
        ...otherParams
      }) => {
        const params = new URLSearchParams();

        // Добавляем другие параметры
        Object.entries(otherParams).forEach(([key, value]) => {
          if (value !== undefined) {
            params.append(key, value.toString());
          }
        });

        // Добавляем HotelIds как отдельные параметры
        if (HotelIds && HotelIds.length) {
          HotelIds.forEach(id => params.append('HotelIds', id.toString()));
        }

        // Добавляем Statuses как отдельные параметры
        if (Statuses && Statuses.length) {
          Statuses.forEach(status =>
            params.append('Statuses', status.toString()),
          );
        }

        // Добавляем ChannelCodes как отдельные параметры
        if (ChannelCodes && ChannelCodes.length) {
          ChannelCodes.forEach(code =>
            params.append('ChannelCodes', code.toString()),
          );
        }

        // Добавляем PaymentTypes как отдельные параметры
        if (PaymentTypes && PaymentTypes.length) {
          PaymentTypes.forEach(type =>
            params.append('PaymentTypes', type.toString()),
          );
        }

        return {
          url: `${CURRENT_BOOKINGS}?${params.toString()}`,
          method: Method.Get,
        };
      },
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetBookingSummaryDataQuery, useGetBookingAllDataQuery } =
  bookingSummaryApi;
