import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_DATA_WALL, CURRENT_BOOKINGS } from '../../endpoints';
import { Method } from '../../types';
import {
  CurrentBookingsResp,
  CurrentBookingsProps,
} from './current-bookings.types';

export const currentBookingsApi = createApi({
  reducerPath: 'currentBookingsApi',
  tagTypes: ['currentBookings'],
  baseQuery: baseQueryWithAuth(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    getCurrentBookingsData: builder.query<
      CurrentBookingsResp,
      CurrentBookingsProps
    >({
      query: ({ GuestId, HotelIds }) => {
        const params = new URLSearchParams();
        params.append('GuestId', GuestId);
        HotelIds.forEach(id => params.append('HotelIds', id));

        return {
          url: `${CURRENT_BOOKINGS}?${params.toString()}`,
          method: Method.Get,
        };
      },
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetCurrentBookingsDataQuery } = currentBookingsApi;
