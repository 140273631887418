import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationsDictionary } from '../public/locales/translation';

const lngDetector = new LanguageDetector(null, {
  order: ['header', 'localStorage', 'cookie'],
  lookupHeader: 'accept-language',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18next',
  caches: ['localStorage', 'cookie'],
});

i18n
  .use(lngDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-EN': {
        translations: translationsDictionary,
      },
    },
    debug: false,
    preload: ['ru-RU', 'en-EN'],
    fallbackLng: 'ru-RU',
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
