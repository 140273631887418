import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_DATA_WALL, GUESTS_ALL_URL } from '../../endpoints';
import { Method } from '../../types';
import { GuestsApiProps, GuestsResponse } from './guests.types';

export const guestsApi = createApi({
  reducerPath: 'guestsApi',
  tagTypes: ['guests'],
  baseQuery: baseQueryWithAuth(BASE_URL_DATA_WALL),
  endpoints: builder => ({
    getGuestsData: builder.query<GuestsResponse, GuestsApiProps>({
      query: ({ PageSize = '15', ...params }) => ({
        url: GUESTS_ALL_URL,
        method: Method.Get,
        params: { PageSize, ...params },
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetGuestsDataQuery } = guestsApi;
