import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthApiProps, AuthApiResponse, RefreshApiProps } from './auth.types';
import {
  AUTH_TOKEN_REFRESH_URL,
  AUTH_TOKEN_URL,
  BASE_URL,
} from '../../endpoints';
import { Method } from '../../types';
import { RootState } from 'app/providers/store/config/store'; 

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = (getState() as RootState).auth;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: builder => ({
    token: builder.mutation<AuthApiResponse, AuthApiProps>({
      query: ({ userName, password }) => ({
        url: AUTH_TOKEN_URL,
        method: Method.Post,
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          userName: userName,
          password: password,
        },
        keepUnusedDataFor: 0.001,
        serializeBody: false,
      }),
    }),
    refreshToken: builder.mutation<AuthApiResponse, RefreshApiProps>({
      query: ({ refreshToken }) => ({
        url: AUTH_TOKEN_REFRESH_URL,
        method: Method.Post,
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          refreshToken: refreshToken,
        },
        keepUnusedDataFor: 0.001,
      }),
    }),
  }),
});

export const { useTokenMutation, useRefreshTokenMutation } = authApi;
