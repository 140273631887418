import React, { FC, PropsWithChildren } from 'react';
import { Box, Paper } from '@mantine/core';
import styles from './layout.module.scss';
import { LeftPageMenu } from './left-page-menu';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className={styles.container}>
      <Paper className={styles.columns}>
        <LeftPageMenu />
      </Paper>
      <main className={styles.main}>{children}</main>
    </Box>
  );
};
