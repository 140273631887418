import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthPageAsync, HomePageAsync, Layout } from 'pages';
import { useAppSelector } from 'app/providers/store/config/hooks';
import { useAutoRefreshToken } from 'modules/user/containers/hooks/useRefreshToken';
import { routes } from 'shared/constants';
import { GuestPageAsync } from 'pages/guest/GuestPage.async';
import { GuestsPageAsync } from 'pages/guests/GuestsPage.async';
import { ReservationsPageAsync } from 'pages/reservations/ReservationsPage.async';
import { ReservationBookingPageAsync } from 'pages/reservation-page/ReservationBookingPage.async';

export const AppRouter = () => {
  const { accessToken, isLoading } = useAppSelector(state => state.auth);
  useAutoRefreshToken();

  if (isLoading) {
    return (
      <div className='loader_wrapper'>
        <span className='loader'></span>
      </div>
    );
  }

  if (accessToken !== null) {
    return (
      <Suspense
        fallback={
          <div className='loader_wrapper'>
            <span className='loader'></span>
          </div>
        }
      >
        <Layout>
          <Routes>
            {/* Домашняя страница */}
            <Route path={routes.home} element={<HomePageAsync />} />

            {/* База гостей, карточка гостя */}
            <Route path={routes.guests} element={<GuestsPageAsync />} />
            <Route path={routes.guest} element={<GuestPageAsync />} />

            {/* Раздел бронирований  */}
            <Route
              path={routes.reservations}
              element={<ReservationsPageAsync />}
            />
            <Route
              path={routes.reservation}
              element={<ReservationBookingPageAsync />}
            />

            <Route path='*' element={<Navigate to={routes.reservations} />} />
          </Routes>
        </Layout>
      </Suspense>
    );
  } else {
    return (
      <Suspense
        fallback={
          <div className='loader_wrapper'>
            <span className='loader'></span>
          </div>
        }
      >
        <Routes>
          <Route path={routes.auth} element={<AuthPageAsync />} />
          <Route path='*' element={<Navigate to={routes.auth} />} />
        </Routes>
      </Suspense>
    );
  }
};
