export enum Method {
  Post = 'POST',
  Get = 'GET',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

export interface ApiResponse {
  err_msg: string | null;
  result: boolean;
}

export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  PartialContent = 206,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  InternalServerError = 500,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}
