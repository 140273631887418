import { useEffect } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'app/providers/store/config/hooks';
import { useNavigate } from 'react-router-dom';
import { useRefreshTokenMutation } from 'app/providers/store/api/auth/auth';
import { routes } from 'shared/constants';
import { authActions } from 'modules/user/containers/auth-container/auth-container.slice';
import { StorageKey, storage } from 'shared/utils/storage_news';

export const useAutoRefreshToken = () => {
  const dispatch = useAppDispatch();
  const { refreshToken, accessToken, expiresIn, isTokenRefreshProcessRunning } =
    useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const [refreshTokenQuery] = useRefreshTokenMutation();
  let isLogout = false;

  const logout = () => {
    storage.clear();
    dispatch(authActions.logout());
    navigate(routes.auth);
    isLogout = true;
  };

  useEffect(() => {
    if (
      !isTokenRefreshProcessRunning &&
      refreshToken &&
      accessToken &&
      expiresIn
    ) {
      // Текущее время в миллисекундах
      const now = Date.now();

      // Время до истечения токена, минус 2 минуты для безопасности
      const timeUntilExpiration = expiresIn - now - 2 * 60 * 1000;

      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          dispatch(authActions.setIsTokenRefreshProcessRunning(true));

          refreshTokenQuery({ refreshToken })
            .then(res => {
              if (isLogout) return; // Если был выполнен логаут, прекратить выполнение

              if ('data' in res) {
                const { access_token, expires_in, refresh_token } = res.data;
                storage.setItems({
                  [StorageKey.AccessToken]: access_token,
                  [StorageKey.ExpiresIn]: expires_in,
                  [StorageKey.RefreshToken]: refresh_token,
                });
                dispatch(authActions.setAccessToken(access_token));
                dispatch(authActions.setExpiresIn(expires_in));
                dispatch(authActions.setRefreshToken(refresh_token));
              } else {
                logout();
              }
              dispatch(authActions.setIsTokenRefreshProcessRunning(false));
            })
            .catch(() => {
              if (isLogout) return; // Если был выполнен логаут, прекратить выполнение
              dispatch(authActions.setIsTokenRefreshProcessRunning(false));
              logout();
            });
        }, timeUntilExpiration);
      } else {
        logout();
      }
    }
  }, [
    accessToken,
    refreshToken,
    expiresIn,
    isTokenRefreshProcessRunning,
    refreshTokenQuery,
    dispatch,
  ]);
};
