import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import {
  BASE_URL_PROMO_SERVICE,
  INTERNAL_ACCOUNT_ANNULATE,
  INTERNAL_ACCOUNT_UPDATE,
  InternalAccount_GetByGuestId_URL,
} from '../../endpoints';
import { Method } from '../../types';
import {
  InternalAnnulateApiProps,
  InternalApiProps,
  InternalUpdateApiProps,
  ReceivingInternalResponse,
} from './receiving-internal-acc.types';

export const receivingInternalAccApi = createApi({
  reducerPath: 'receivingInternalAccApi',
  tagTypes: ['receivingInternalAcc'],
  baseQuery: baseQueryWithAuth(BASE_URL_PROMO_SERVICE),
  endpoints: builder => ({
    getReceivingInternalAccData: builder.query<
      ReceivingInternalResponse,
      InternalApiProps
    >({
      query: params => ({
        url: InternalAccount_GetByGuestId_URL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
    putInternalAccBalanceUpdate: builder.mutation<
      unknown,
      InternalUpdateApiProps
    >({
      query: body => ({
        url: INTERNAL_ACCOUNT_UPDATE,
        method: Method.Put,
        body,
      }),
    }),
    putInternalAccBalanceAnnulate: builder.mutation<
      unknown,
      InternalAnnulateApiProps
    >({
      query: body => ({
        url: INTERNAL_ACCOUNT_ANNULATE,
        method: Method.Put,
        body,
      }),
    }),
  }),
});

export const {
  useGetReceivingInternalAccDataQuery,
  usePutInternalAccBalanceAnnulateMutation,
  usePutInternalAccBalanceUpdateMutation
} = receivingInternalAccApi;